<template>
  <div class="container">
    <h3>Möchtest Du mitspielen oder zuschauen?</h3>
    <button id="lower" @click="multiplayer">{{ multiplayerText }}</button>
    <button id="lowest" @click="spectator">{{ spectatorText }}</button>
  </div>
</template>

<script setup>
    import { useRouter } from 'vue-router'

    import { computed, inject } from 'vue';

    const socket = inject('socket')
    const router = useRouter()

    const uiconfig = inject('uiconfig')
    const multiplayerText = computed(() => uiconfig.multiplayer.value)
    const spectatorText = computed(() => uiconfig.spectator.value)

    const multiplayer = () => {
      sessionStorage.setItem('gamemode', 'multiplayer')
      router.push('/tutorial')
    }

    const spectator = () => {
      sessionStorage.setItem('gamemode', 'spectator')
      socket.emit('spectator')
      router.push('/')
    }
</script>

<style scoped>
  .container{
    padding: 2rem;
    height: 100%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  button{
    width: 100%;
    padding: .5rem 1.5rem;
    border: 1px solid white;
    border-radius: 0;
    background-color: black;
    color: white;
    cursor: pointer;
    
    font-size: 1.25rem;
    min-height: 5rem;
  }
  button:hover{
    transform: scale(105%);
  }
</style>
