<template>
    <main class="background-shape">
        <div class="mainContent flex">
            <Component_haello v-if="showHaello"/>
            <Component_gamemode v-else/>
        </div>
    </main>
</template>
  
<script setup>

    import Component_haello from '@/components/Component_haello.vue'
    import Component_gamemode from '@/components/Component_gamemode.vue'

    import { ref, onMounted, onUnmounted } from 'vue'

    const showHaello = ref(false)
    const timer = ref(null)

    onMounted(() => {
        showHaello.value = true
        timer.value = setTimeout(() => {
            showHaello.value = false
        }, 2500)
    })

    onUnmounted(() => {
        clearTimeout(timer.value)
    })
</script>

<style scoped>
.mainContent{
    width: 100vw;
    height: 100vh;
}
</style>