<template>
	<router-view />
	<Component_impressum />
</template>

<script setup>
import { provide, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { state, socket } from './socket';
import { config } from './config';
import Component_impressum from './components/Component_impressum.vue';

const router = useRouter();

const spectatorLocations = [
	'none',
	'attraction',
	'introduction',
	'quizRoom',
	'leaderboard',
];
const playerLocations = [
	'none',
	'tutorial',
	'waiting',
	'quizRoom',
	'leaderboard',
];

onMounted(() => {
	if (window.location.href.includes('config')) {
		// do nothing
	} else {
		router.push('/');
	}
});

provide('socket', socket);
provide('uiconfig', config);

// Handle resetClient
const resetClient = computed(() => state.resetClients.value);
watch(resetClient, (newValue) => {
	if (newValue) {
		window.location.reload();
	}
});

//Handle movePlayer
const playerLocation = computed(() => {
	const index = state.playersLocation.value;
	return playerLocations[index];
});
watch(playerLocation, (newLocation) => {
	router.push({ name: newLocation });
	console.log(`App.vue determined new player location to be ${newLocation}`);
});

// Handle moveSpectator
const spectatorLocation = computed(() => {
	const index = state.spectatorsLocation.value;
	return spectatorLocations[index];
});
watch(spectatorLocation, (newLocation) => router.push({ name: newLocation }));

// Check if gamemode is already set to spectator and send spectator to attraction screen
const isSpectator = sessionStorage.getItem('gamemode') === 'spectator';
setTimeout(() => {
	if (isSpectator) {
		router.push({ name: 'attraction' });
		socket.emit('spectator');
	}
}, 1000);
</script>

<style></style>
