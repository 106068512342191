import { ref } from "vue"
import data from './config.json'

export const config = {
    singleplayer: ref(data.uiconfig.singleplayer),
    multiplayer: ref(data.uiconfig.multiplayer),
    spectator: ref(data.uiconfig.spectator),
    tutorialForward: ref(data.uiconfig.tutorialForward),
    tutorialEnd: ref(data.uiconfig.tutorialEnd),
    toggleTimer1: ref(data.uiconfig.toggleTimer1),
    toggleTimer2: ref(data.uiconfig.toggleTimer2),
    reset: ref(data.uiconfig.reset),
    backToStart: ref(data.uiconfig.backToStart), 
    toTheLaend: ref(data.uiconfig.toTheLaend)
}
console.table(config)
