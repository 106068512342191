<template>
  <div class="container">
    <h1><b>HÄLLO!</b></h1>
    <p>Gleich gehts weiter!</p>
  </div>
</template>

<script setup>
</script>

<style scoped>
  h1{
    font-size: 4rem;
  }
  p{
    font-size: 2rem;
  }
</style>
