import { createRouter, createWebHashHistory } from 'vue-router';
import LandingView from '@/views/general/LandingView.vue';

const routes = [
	// Root
	{
		path: '/',
		name: 'landing',
		component: LandingView,
	},

	// Spectators
	{
		path: '/attraction',
		name: 'attraction',
		component: () => import('../views/general/AttractionView.vue'),
	},
	{
		path: '/tutorial',
		name: 'tutorial',
		component: () => import('../views/general/TutorialView.vue'),
	},
	{
		path: '/quizRoom',
		name: 'quizRoom',
		component: () => import('../views/general/QuizRoomView.vue'),
	},
	{
		path: '/leaderboard',
		name: 'leaderboard',
		component: () => import('../views/general/LeaderboardView.vue'),
	},
	{
		path: '/waiting',
		name: 'waiting',
		component: () => import('../views/general/WaitingView.vue'),
	},

	// Reset Game Manually
	{
		path: '/reset',
		name: 'reset',
		component: () => import('../views/general/ResetGameView.vue'),
	},
	{
		path: '/config',
		name: 'config',
		component: () => import('../views/general/ConfigGameView.vue'),
	},

	//Errors
	{
		path: '/hoppala',
		name: 'hoppala',
		component: () => import('../views/error/HoppalaView.vue'),
	},
	{
		path: '/:catchAll(.*)',
		name: 'NotFound',
		component: () => import('../views/error/NotFound.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
