<template>
    <div id="impressum"><a href="https://www.thelaend.de/impressum/">Impressum</a></div>
</template>

<script setup>

</script>

<style scoped>
#impressum{
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
#impressum > a{
  text-decoration: none;
  color: #88888888;
  margin-bottom: .25em;
  font-size: 10px;
}
</style>