import { ref } from "vue"
import { useRouter } from "vue-router"
import { io } from "socket.io-client"
import { v4 } from "uuid"

export const state = {
    numQuestions: ref(null), 
    numPlayers: ref(null),
    baseScore: ref(null),
    maxPoints: ref(null),

    players: ref([]),

    hasWaitingMultiplayerPlayer: ref(null),
    isSession: ref(null),
    startInstantly: ref(null), 
    
    timeForMorePlayers: ref(null),
    timeToAnswer: ref(null),
    timeToDisplayAnswer: ref(null),
    timeToDisplayPodium: ref(null),
    
    numCategory: ref(null),
    focusCategory: ref(null),

    hoppala: ref(null),
    resetClients: ref(null),
    gameStartTimer: ref(null),
    currentQuestion: ref(null),
    currentQuestionIndex: ref(null),
    revealAnswer: ref(false),
    playersLocation: ref(null),
    spectatorsLocation: ref(null)
}

const URL = process.env.NODE_ENV === "production" ? undefined : "http://localhost:3500"
const clientId = localStorage.getItem('clientId') ? localStorage.getItem('clientId') : localStorage.setItem('clientId', v4());

export const socket = io(URL, {
    query: {userId: clientId}, 
    autoConnect: false, 
    reconnectionDelay: 100, // defaults to 1000
    reconnectionDelayMax: 4000 // defaults to 5000
})

const router = useRouter();

setTimeout(() => {
    if(clientId){
        socket.connect();
    }else{
        console.log(`clientId not available, connection refused. Reloading page!`);
        window.location.reload();
    }
}, 100)

// Connect
socket.on('connect', () => {
    //console.log(`Socket Id: ${socket.id}`);
    //console.log(`Client UUID: ${clientId}`);

    if(socket.recovered){
        console.info(`Socket connection was recovered!`);
    }
    else{
        //console.info(`Socket connection was newly established!`);
        console.log(`isSession? ${state.isSession.value}`)
    }
})

// Info
socket.on('requestClientId', () => {
    console.log(`clientId request accepted, sending clientId...`);
    socket.emit('clientId', clientId);
    console.log(`...done`);
})

socket.on('gameStateUpdate', (gameState) => {
    state.numQuestions.value = gameState.numQuestions;
    state.numPlayers.value = gameState.numPlayers;
    state.baseScore.value = gameState.baseScore;
    state.maxPoints.value = gameState.maxPoints;

    state.players.value = gameState.players;

    state.hasWaitingMultiplayerPlayer.value = gameState.hasWaitingMultiplayerPlayer;
    state.isSession.value = gameState.isSession;
    state.startInstantly.value = gameState.startInstantly;

    state.timeForMorePlayers.value = gameState.timeForMorePlayers;
    state.timeToAnswer.value = gameState.timeToAnswer;
    state.timeToDisplayAnswer.value = gameState.timeToDisplayAnswer;
    state.timeToDisplayPodium.value = gameState.timeToDisplayPodium;

    state.numCategory.value = gameState.numCategory;
    state.focusCategory.value = gameState.focusCategory;
    
    //console.info('gameState update received.');
})

/*
socket.on('ownID', (socketID) => {
    sessionStorage.setItem('socketID', socketID);
})
*/

// Game Start Timer
socket.on('startGameStartTimer', () => {
    state.gameStartTimer.value = state.timeForMorePlayers.value;
})

socket.on('resetGameStartTimer', () => {
    state.gameStartTimer.value = null;
})

socket.on('instantGameStartTimer', () => {
    state.gameStartTimer.value = 5000;
})

// Players
socket.on("playersToHome", () => {
    console.log("Players to home");
    window.location.reload();
})
socket.on("playersToQuiz", () => {
    console.info("Players moving to quiz");
    state.playersLocation.value = 3 ;
})

socket.on("newQuestion", (questionData, index) => {
    //console.info('received new question successfully')
    state.revealAnswer.value = false;
    state.currentQuestion.value = questionData;
    state.currentQuestionIndex.value = index;
})

socket.on("revealAnswer", () => {
    //console.log("Reveal answer");
    state.revealAnswer.value = true;
})

socket.on("playersToLeaderboard", () => {
    state.playersLocation.value = 4;
})

// Spectators
socket.on("spectatorsToHome", () => {
    console.log("Spectators to home");
    state.spectatorsLocation.value = 1;
})

socket.on("spectatorsToIntroduction", () => {
    console.log("Spectators to introduction");
    state.spectatorsLocation.value = 2;
})

socket.on("spectatorsToQuiz", () => {
    console.log("Spectators to quiz");
    state.spectatorsLocation.value = 3;
})

socket.on("spectatorsToLeaderboard", () => {
    state.spectatorsLocation.value = 4;
})

// Error Session Running
socket.on("hoppala", () => {
    router.push('/hoppala');
})

// Reset
socket.on("resetClients", () => {
    const isSpectator = sessionStorage.getItem('gamemode') === 'spectator'
    console.log("Resetting clients");
    state.resetClients.value = true;
        setTimeout(() => {
            state.resetClients.value = false;
        }, 500)
    if(!isSpectator){
        sessionStorage.removeItem('gamemode')
    }else{
        console.log(`Client is in spectator mode, leaving gamemode in sessionStorage.`)
    }  
})